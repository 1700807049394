/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    blockquote: "blockquote",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Acabo de descubrir el repositorio de hardware libre más grande que he visto hasta la fecha, se trata de Open Circuits y no se como he tardado tanto en encontrarlo. Se trata de una wiki que recopila información sobre electrónica libre, desde los circuitos más básicos a complejos sistemas de hardware."), "\n", React.createElement(_components.p, null, "De esta forma se definen ellos mismos:"), "\n", React.createElement(_components.blockquote, null, "\n", React.createElement(_components.p, null, "Open Circuits is a wiki for sharing ", React.createElement(_components.a, {
    href: "http://www.opencircuits.com/Open_source_electronics",
    title: "Open source electronics"
  }, "open source electronics"), " knowledge, schematics, board layouts, ports and parts libraries."), "\n"), "\n", React.createElement(_components.p, null, "Y dentro de esta definición caben cosas tan dispersas como ", React.createElement(_components.a, {
    href: "http://blog.reprap.org/",
    title: "RepRap: Blog"
  }, "impresoras 3D"), ", ", React.createElement(_components.a, {
    href: "http://www.opencellphone.org/index.php?title=Main_Page",
    title: "TuxPhone"
  }, "teléfonos móviles libres"), " o ", React.createElement(_components.a, {
    href: "http://www.opensparc.net/",
    title: "OpenSPARC"
  }, "microprocesadores de código abierto"), "."), "\n", React.createElement(_components.p, null, "En definitiva un gran descubrimiento donde perderme entre proyectos libres."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
